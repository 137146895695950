<template>
  <div class="nav">
    <nav>
      <div v-for="item in nav" :key="item.id" class="menuItemDiv" :class="{'active': item.path === route.path.split('/')[1]}">
        <div class="menuItem" @mouseenter="setSubMenuHeight" @mouseleave="removeSubMenuHeight" v-if="item.children && item.children.length">
          <div class="menuItemText">
            {{ item.name }}
          </div>
          <div class="subMenu" :style="{height: subMenuHeight + 'px'}">
            <nuxt-link :to="toPath(item, child)" class="subMenuItem" v-for="child in item.children" :key="child.id">
              {{ child.name }}
            </nuxt-link>
          </div>
        </div>
        <div class="menuItem" v-else>
<!--          :class="{'active': route.path.includes(item.path)}"-->
          <nuxt-link :to="'/' + item.path" class="menuItemText">
            {{ item.name }}
          </nuxt-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script setup>
import {getNav} from "../../../apis";

const route = useRoute();
const Router = useRouter();

function toPath(item, child){
  if (child.isTopic) {
    return `${child.path}`
  } else {
    return `/${item.path}/${child.path}`
  }
}


/**
 * @description: hover展开子菜单时候，动态设置子菜单高度
 * */
const subMenuHeight = ref(0);
function setSubMenuHeight(el){
  try {
    let _len = el.target.children[1].children.length;
    el.target.children[1].style.height = (_len * 53) + 'px'
  }catch (e) {
    console.log(e)
  }
}
function removeSubMenuHeight(el){
  try {
    el.target.children[1].style.height = 0
  }catch (e) {
    console.log(e)
  }
}



/**
 * @description: 获取导航数据
 * */
const nav = ref([]);
const navRes = await useAsyncData("getNav", () => getNav());
if (navRes.data.value.code !== 200) {
  nav.value = []
  throw createError({statusCode: navRes.data.value.code, statusMessage: encodeURIComponent("获取导航失败")})
} else {
  nav.value = [{name:"首页", path: ""}, ...navRes.data.value.data]
}
</script>

<style scoped lang="scss">
.nav {
  box-shadow: 8px 4px 38px 1px rgba(0, 10, 39, 0.1);
  width: 100%;
  background-color: rgba(21,77,152,.95);
  position: sticky;
  top: 0;
  z-index: 99999999999999999999;
  nav {
    width: var(--body-min-width);
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    .menuItemDiv {
      &:last-child .menuItem{
        margin-right: 0;
      }
    }
    .active {
      background-color: var(--main-blue-color) ;
    }
    .menuItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      height: 60px;
      width: 162.5px;
      .menuItemText {
        width: 150px;
        font-size: 20px;
        text-align: center;
        line-height: 60px;
        color: #ffffff;
      }
      .subMenu {
        position: absolute;
        left: 0;
        top: 60px;
        min-width: 150px;
        width: auto;
        border-radius: 2px;
        background: rgba(21,77,152,.95);
        //height: 0;
        overflow: auto;
        transition: all 300ms ease-in;
        padding: 0 10px;
        &::-webkit-scrollbar {
          display: none;
        }
        .subMenuItem {
          font-size: 18px;
          padding-left: 23px;
          line-height: 53px;
          display: flex;
          align-items: center;
          padding-right: 10px;
          justify-content: space-between;
          transition: all 500ms ease-in-out;
          white-space: nowrap;
          color: var(--white);
          > img {
            width: 5px;
            height: 9px;
            margin-left: 15px;
          }
          &:hover {
            transition: all 500ms ease-in-out;
            padding-left: 33px;
            background: var(--main-blue-color);
            border-radius: 2px;
            color: var(--white);
          }
        }
      }
      &:hover {
        .subMenu {
          padding: 10px;
          z-index: 999;
        }
        &:after{
          width: 100%;
          transition: 500ms all ease-in-out;
        }
      }
    }
  }
}
</style>